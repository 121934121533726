.link {
  @apply font-semibold leading-6;
}

.link-primary {
  @apply text-primary hover:text-primary-700;
}

.link-secondary {
  @apply text-tertiary-500 hover:text-primary;
}
