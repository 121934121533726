@import '@angular/cdk/overlay-prebuilt.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
.btn {
  @apply font-semibold text-sm select-none shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2;
}

.btn-primary {
  @apply bg-primary text-white focus-visible:outline-primary disabled:bg-opacity-50;
}
.btn-primary:not(:disabled) {
  @apply hover:bg-primary-700;
}

.btn-secondary {
  @apply bg-white text-gray-800 focus-visible:outline-gray-800 disabled:text-gray-400 ring-1 ring-gray-300 ring-inset;
}
.btn-secondary:not(:disabled) {
  @apply hover:bg-gray-50;
}

.btn-tertiary {
  @apply bg-tertiary text-white focus-visible:outline-tertiary disabled:bg-opacity-50 ring-1 ring-gray-300 ring-inset;
}
.btn-tertiary:not(:disabled) {
  @apply hover:bg-tertiary-600;
}

.btn-danger {
  @apply bg-danger text-white focus-visible:outline-danger disabled:bg-opacity-50;
}
.btn-danger:not(:disabled) {
  @apply hover:bg-danger-600;
}

.btn-link {
  @apply bg-white text-primary focus-visible:outline-primary disabled:bg-opacity-50 ring-white underline underline-offset-4 shadow-none;
}
.btn-link:not(:disabled) {
  @apply hover:bg-gray-100;
}
.btn-link:disabled {
  @apply text-gray-400;
}

.btn-xs {
  @apply py-1 px-2 rounded;
}

.btn-sm {
  @apply py-1.5 px-2.5 rounded-md;
}

.btn-base {
  @apply py-2 px-3 rounded-md;
}

.btn-lg {
  @apply py-2.5 px-3.5 rounded-md;
}

.btn-xl {
  @apply py-3 px-4 rounded-md;
}

.cdk-overlay-pane.modal-xs {
  max-width: calc(100vw - theme("width.4") - theme("width.4"));
  width: theme("width.96");
}
.cdk-overlay-pane.modal-sm {
  max-width: calc(100vw - theme("width.4") - theme("width.4"));
  width: theme("screens.sm");
}
.cdk-overlay-pane.modal-md {
  max-width: calc(100vw - theme("width.4") - theme("width.4"));
  width: theme("screens.md");
}
.cdk-overlay-pane.modal-lg {
  max-width: calc(100vw - theme("width.4") - theme("width.4"));
  width: theme("screens.lg");
}
.cdk-overlay-pane.modal-xl {
  max-width: calc(100vw - theme("width.4") - theme("width.4"));
  width: theme("screens.xl");
}
.cdk-overlay-pane.modal-2xl {
  max-width: calc(100vw - theme("width.4") - theme("width.4"));
  width: theme("screens.2xl");
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  cursor: pointer;
  @apply bg-opacity-50 bg-gray-300;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  @apply bg-opacity-50 bg-gray-400;
}

:root {
  scrollbar-color: rgba(156, 163, 175, 0.5) rgba(209, 213, 219, 0.5) !important;
  scrollbar-width: thin !important;
}

.no-scrollbar {
  overflow: hidden;
}
@media (width >= 768px) {
  .no-scrollbar {
    padding-right: 10px;
  }
}

.cdk-overlay-w-full {
  @apply w-full left-0 !important;
}

/*
 * see conditions here: projects/ui/src/lib/dynamic-form-regular/services/dynamic-form-regular-error-state-matcher.service.ts
 */
input.ng-invalid.ng-dirty,
textarea.ng-invalid.ng-dirty,
select.ng-invalid.ng-dirty,
owt-input-select.ng-invalid.ng-dirty > div,
ngx-codemirror.ng-invalid.ng-dirty {
  @apply text-danger-900 ring-1 ring-inset ring-danger-300 placeholder:text-danger-300 focus:ring-2 focus:ring-inset focus:ring-danger-500 !important;
}

.ng-invalid.ng-submitted input.ng-invalid,
.ng-invalid.ng-submitted textarea.ng-invalid,
.ng-invalid.ng-submitted select.ng-invalid,
.ng-invalid.ng-submitted owt-input-select.ng-invalid > div,
.ng-invalid.ng-submitted ngx-codemirror.ng-invalid {
  @apply text-danger-900 ring-1 ring-inset ring-danger-300 placeholder:text-danger-300 focus:ring-2 focus:ring-inset focus:ring-danger-500 !important;
}

.btn-dropdown-secondary {
  @apply text-gray-800 focus-visible:outline-gray-800 disabled:text-gray-400 font-normal ring-1 ring-gray-300 ring-inset;
}
.btn-dropdown-secondary:not(:disabled) {
  @apply hover:ring-gray-400;
}

.link {
  @apply font-semibold leading-6;
}

.link-primary {
  @apply text-primary hover:text-primary-700;
}

.link-secondary {
  @apply text-tertiary-500 hover:text-primary;
}

.container-form {
  @apply max-w-4xl sm:mx-auto overflow-hidden rounded-lg bg-secondary;
}

@layer components {
  .bg-inherit {
    background-color: inherit;
  }
  .bg-current {
    background-color: currentcolor;
  }
  a.disabled {
    @apply pointer-events-none opacity-30;
  }
}