.btn {
  @apply font-semibold text-sm select-none shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2;
}

.btn-primary {
  @apply bg-primary text-white focus-visible:outline-primary disabled:bg-opacity-50;

  &:not(:disabled) {
    @apply hover:bg-primary-700;
  }
}

.btn-secondary {
  @apply bg-white text-gray-800 focus-visible:outline-gray-800 disabled:text-gray-400 ring-1 ring-gray-300 ring-inset;

  &:not(:disabled) {
    @apply hover:bg-gray-50;
  }
}

.btn-tertiary {
  @apply bg-tertiary text-white focus-visible:outline-tertiary disabled:bg-opacity-50 ring-1 ring-gray-300 ring-inset;

  &:not(:disabled) {
    @apply hover:bg-tertiary-600;
  }
}

.btn-danger {
  @apply bg-danger text-white focus-visible:outline-danger disabled:bg-opacity-50;

  &:not(:disabled) {
    @apply hover:bg-danger-600;
  }
}

.btn-link {
  @apply bg-white text-primary focus-visible:outline-primary disabled:bg-opacity-50 ring-white underline underline-offset-4 shadow-none;

  &:not(:disabled) {
    @apply hover:bg-gray-100;
  }

  &:disabled {
    @apply text-gray-400;
  }
}

.btn-xs {
  @apply py-1 px-2 rounded;
}

.btn-sm {
  @apply py-1.5 px-2.5 rounded-md;
}

.btn-base {
  @apply py-2 px-3 rounded-md;
}

.btn-lg {
  @apply py-2.5 px-3.5 rounded-md;
}

.btn-xl {
  @apply py-3 px-4 rounded-md;
}
