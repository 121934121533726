/*
 * see conditions here: projects/ui/src/lib/dynamic-form-regular/services/dynamic-form-regular-error-state-matcher.service.ts
 */

input.ng-invalid.ng-dirty,
textarea.ng-invalid.ng-dirty,
select.ng-invalid.ng-dirty,
owt-input-select.ng-invalid.ng-dirty > div,
ngx-codemirror.ng-invalid.ng-dirty {
  @apply text-danger-900 ring-1 ring-inset ring-danger-300 placeholder:text-danger-300 focus:ring-2 focus:ring-inset focus:ring-danger-500 #{!important};
}

.ng-invalid.ng-submitted {
  input.ng-invalid,
  textarea.ng-invalid,
  select.ng-invalid,
  owt-input-select.ng-invalid > div,
  ngx-codemirror.ng-invalid {
    @apply text-danger-900 ring-1 ring-inset ring-danger-300 placeholder:text-danger-300 focus:ring-2 focus:ring-inset focus:ring-danger-500 #{!important};
  }
}

.btn-dropdown-secondary {
  @apply text-gray-800 focus-visible:outline-gray-800 disabled:text-gray-400 font-normal ring-1 ring-gray-300 ring-inset;

  &:not(:disabled) {
    @apply hover:ring-gray-400;
  }
}
