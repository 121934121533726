@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@angular/cdk/overlay-prebuilt.css';
@import 'styles/buttons';
@import 'styles/modal';
@import 'styles/scrollbar';
@import 'styles/dropdowns';
@import 'styles/form';
@import 'styles/link';
@import 'styles/containers';

// This is a workaround for native bg-inherit class which does not exist in this veriosn of Tailwind
@layer components {
  .bg-inherit {
    background-color: inherit;
  }

  .bg-current {
    background-color: currentcolor;
  }

  a.disabled {
    @apply pointer-events-none opacity-30;
  }
}
