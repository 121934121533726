::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  cursor: pointer;

  @apply bg-opacity-50 bg-gray-300;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;

  @apply bg-opacity-50 bg-gray-400;
}

:root {
  scrollbar-color: rgb(156 163 175 / 50%) rgb(209 213 219 / 50%) !important;
  scrollbar-width: thin !important;
}

.no-scrollbar {
  overflow: hidden;

  @media (width >= 768px) {
    padding-right: 10px;
  }
}
